import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './shared/components/layout';
import { AuthGuard } from '../app/core/services/auth.guard';
import '@angular/localize/init';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },

    children: [
      {
        path: 'dashboard',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./pages/dashboard/routes').then((m) => m.routes)
      },
      {
        path: 'deployment',
        canActivate: [AuthGuard], 
        loadChildren: () => import('./pages/deployments/routes').then((m) => m.routes),  
      },
      {
        path: 'create-deployment',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/deployments/create-deployments/routes').then((m) => m.routes)
      },
      // {
      //   path: 'deployment-details',
      //   canActivate: [AuthGuard],
      //   loadChildren: () => import('./pages/deployments/deployment-details/routes').then((m) => m.routes)
      // },
      // {
      //   path: 'edit-deployment',
      //   canActivate: [AuthGuard],
      //   loadChildren: () => import('./pages/deployments/create-deployments/routes').then((m) => m.routes)
      // },
      {
        path: 'deployment-logs',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/deployments/deployment-logs/routes').then((m) => m.routes)
      },
      {
        path: 'endpoints',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/endpoints/routes').then((m) => m.routes)
      },
      {
        path: 'create-endpoint',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/endpoints/create-endpoints/routes').then((m) => m.routes)
      },
      {
        path: 'configs',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/configurations/routes').then((m) => m.routes)
      },
      {
        path: 'create-config',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/configurations/create-config/routes').then((m) => m.routes)
      },
      {
        path: 'secrets',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/secrets/routes').then((m) => m.routes)
      },
      {
        path: 'create-secret',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/secrets/create-secret/routes').then((m) => m.routes)
      },
      {
        path: 'tools',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/tools/routes').then((m) => m.routes)
      },
      {
        path: 'create-tool',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/tools/create-tool/routes').then((m) => m.routes)
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/settings/routes').then((m) => m.routes)
      },
      {
        path: 'volumes',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/volumes/routes').then((m) => m.routes)
      },
      {
        path: 'account-settings',
        loadChildren: () => import('./pages/account-settings/routes').then((m) => m.routes),
        data: {
          title: $localize`:account-settings:Account Settings`
        }
      }

    ]
  },
  {
    path: '404',
    loadComponent: () => import('./pages/page404/page404.component').then(m => m.Page404Component),
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    loadComponent: () => import('./pages/page500/page500.component').then(m => m.Page500Component),
    data: {
      title: 'Page 500'
    }
  },
  // {
  //   path: 'login',
  //   loadComponent: () => import('./pages/login/login.component').then(m => m.LoginComponent),
  //   data: {
  //     title: 'Login Page'
  //   }
  // },
  {
    path: 'callback',
    loadComponent: () => import('./shared/components/redirect/redirect.component').then(m => m.RedirectComponent),
  },
  {
    path: 'register',
    loadComponent: () => import('./pages/register/register.component').then(m => m.RegisterComponent),
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'environment',
    loadComponent: () => import('../app/pages/environment/environment.component').then(m => m.EnvironmentComponent),
    data: {
      title: 'Environment Page'
    }
  },
  { path: '**', redirectTo: 'dashboard' }
];
